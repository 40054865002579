// import { FaDollarSign } from 'react-icons/fa'
import Image from "next/image"
import { FaRegCheckCircle } from "@react-icons/all-files/fa/FaRegCheckCircle";
import { FaRegTimesCircle } from '@react-icons/all-files/fa/FaRegTimesCircle'
import { FaDollarSign } from '@react-icons/all-files/fa/FaDollarSign'

const add_unit = (value, unit, use_symbols) => {
  // console.log(value, unit)
  // console.log('ADD UNIT: '+ value + ' ' + unit)
  if (unit === 'int') {
    return value
  } else if (value === 0 || value === '0') {
    return 'Any'
  } else if (['kg', 'cm', 'mm', 'in'].includes(unit)) {
    return (value + unit)
  } else if (unit === 'dollars') {
    return value
    // return '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else if (unit === 'choice' && value) {
    value = value.replace(/_/g, ' ')
    value = value.replace(' 3d', ' 3D')
    return value
    // for (const index in value) {
      // value[index] = value[index].replace('_', ' ')
    // }
  } else if (unit === 'ratings' && value) {
    value = parseFloat(value)
    if (value >= 7.8) {
      return 'Excellent'
    } else if (value >= 7.5) {
      return 'Great'
    } else if (value >= 6.5) {
      return 'Good'
    }
  } else if (unit === 'ratings2' && value) {
    value = parseFloat(value)
    if (value >= 8.0) {
      return 'Excellent'
    } else if (value >= 7.5) {
      return 'Great'
    } else if (value >= 7.0) {
      return 'Good'
    } else if (value >= 6.5) {
      return 'Ok'
    } else if (value < 6.5 && value > 0) {
      return 'Poor'
    } else {
      return "N/A"
    }
  } else if (unit === 'picture_quality' && value) {
    value = parseFloat(value)
    if (value >= 8.5) {
      return 'Excellent'
    } else if (value >= 7.75) {
      return 'Great'
    } else if (value >= 7) {
      return 'Good'
    } else {
      return 'Ok'
    }
  } else if (unit === 'room_brightness' && value) {
    value = parseFloat(value)
    if (value >= 8.5) {
      return 'Very Bright Room'
    } else if (value >= 8) {
      return 'Bright Room'
    } else if (value >= 7.5) {
      return 'Moderate Light'
    } else if (value >= 7) {
      return 'Minimal Light'
    } else {
      return 'Dark Room'
    }
  } else if (unit === 'viewing_angle' && value) {
    value = parseFloat(value)
    if (value >= 8) {
      return 'Very Wide'
    } else if (value >= 7) {
      return 'Wide'
    } else {
      return 'Standard'
    }
  } else if (unit === 'slashes' && value) {
    return value.replace(/_/g, '/')
    // for (const index in value) {
      // value[index] = value[index].replace('_', ' ')
    // }
  } else if (unit ==='psi') {
    return parseInt(value) / 1000 + 'k PSI'
  } else if (unit === 'Hz') {
      return value + 'Hz'
  } else if (unit === 'number') {
      if (value === 0) {
        return 'N/A'
      } else {
        return value
      }
  } else if (unit === 'boolean') {
    if (use_symbols) {
      if (value === true || value === 'True') {
        return <FaRegCheckCircle/>
      } else if (value === false || value === 'False') {
        return <FaRegTimesCircle className='red-false'/>
      } else {
        return value
      }
    } else {
      if (value === true || value === 'True') {
        return 'Yes'
      } else if (value === false || value === 'False') {
        return 'No'
      } else {
        return 'Any'
      }
    }
  } else if (unit === 'additional_images') {
    const images = []
    for (let img in value) {
      images.push(
        <span key={'additional_colors_span'+img}>
          <Image
            height={56}
            width={56}
            key={'additional-image-'+img}
            className="additional-images"
            src={value[img].src}
            placeholder={'blur'}
            blurDataURL={value[img].src}
            alt={value[img].alt}
          />
        </span>
      )
    }
    return images
  } else if (value === '0') {
    return 'N/A'
  } else {
    return value
  }
}

const getToastMessage = (slug, old_values, save_values, current_question) => {
  if (slug === 'ski') {
    if ([2,3,4].includes(current_question)) {
      if (old_values !== save_values) {
        const toastElement = (
          <div>
            <div className={'toast-text'}>
              Your ski sizes have been updated to reflect your filter change.
            </div>
            <div className={'action-btn-gld continue-btn btn-m10 small'} onClick={() => {this.props.goBackToQuestionX(1)}}>
              Undo Sizing Change and Turn Off Auto-Size-Updates
            </div>
          </div>
        )
        return (toastElement)
      }
    }
  }
  return (null)
}

const getCostSymbols = (cost) => {
  let dollars = []
  if (cost) {
    for (let i = 0; i < cost; i++) {
      dollars.push(
        <FaDollarSign key={'dollars-'+i}/>
      )
    }
  }
  return dollars
}

export {
  add_unit, getToastMessage, getCostSymbols
}
